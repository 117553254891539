import {Component, inject, Signal, WritableSignal} from '@angular/core';
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {faMoon, faSun} from "@fortawesome/free-solid-svg-icons";
import {DarkModeService} from "../../core/services/dark-mode.service";

@Component({
  selector: 'app-button-dark-mode',
  standalone: true,
  imports: [
    FaIconComponent
  ],
  template: `
    <div class="relative inline-block w-12 h-6">
      <input type="checkbox" id="toggle" class="sr-only peer" [checked]="darkmode()"/>
      <label for="toggle" class="block bg-gray-300 peer-checked:bg-blue-600 w-12 h-6 rounded-full cursor-pointer transition-colors duration-300" (click)="darkmode.set(!darkmode())"></label>
      <div class="dot absolute left-1 top-1 w-4 h-4 rounded-full bg-white flex items-center justify-center transition-transform duration-300 transform peer-checked:translate-x-6">
        @if(!darkmode()){
          <fa-icon class="text-gray-700" [icon]="faSun"></fa-icon>
        } @else {
          <fa-icon class="text-gray-700" [icon]="faMoon"></fa-icon>
        }
      </div>
    </div>`
})
export class ButtonDarkModeComponent {
  public darkmode:WritableSignal<boolean> = inject(DarkModeService).darkMode

  protected readonly faMoon = faMoon;
  protected readonly faSun = faSun;
}
